import "./WelcomeModal.css";
import Modal, { ModalCenter } from "../layout/Modal";
import { Button } from "../common/buttons";
import State from "../../state";
import { hideWelcomeModalEvent } from "../../analytics";

import { observer } from "mobx-react-lite";

export default observer(function WelcomeModal() {
	if (!State.showWelcomeModal) {
		return null;
	}

	function handleClose() {
		State.hideWelcomeModal();
		hideWelcomeModalEvent();
	}

	return (
		<Modal
			title="The Annual Interdimensional Wizards Convention"
			onClose={handleClose}
		>
			<p>
				{
					"Wizard's Place is proud to host the 1548th Annual Wizards Convention!"
				}
			</p>
			<p>
				{
					"Wizards from across the world, and through time and space, will gather to engage in a variety of activities, including:"
				}
			</p>
			<ul>
				<li>{"Discussing the future of the Magical World."}</li>
				<li>
					{"Sharing the latest advancements in the art of spells."}
				</li>
				<li>{"Potion recipes workshops."}</li>
				<li>{"And much, much more!"}</li>
			</ul>
			<ModalCenter>
				<Button
					inline
					large
					text="Explore the site!"
					onClick={handleClose}
				/>
			</ModalCenter>
		</Modal>
	);
});
