export function textContainsWords(text, words, minWordCount) {
	const wordCount = countWordsInText(text, words);
	console.log(words, text, wordCount, minWordCount);
	return wordCount >= minWordCount;
}

export function countWordsInText(text, words) {
	text = text.toLowerCase();
	return words.reduce(
		(count, word) => count + (text.includes(word.toLowerCase()) ? 1 : 0),
		0
	);
}
