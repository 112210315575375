import { voiceState } from "../../voice";
import { textContainsWords } from "../../utils/textUtils";

import { VoiceListener } from "@faintlines/voice-kit";

export default function SpellListener({
	words,
	minWordCount,
	stopOnSuccess,
	onSpell,
}) {
	return (
		<VoiceListener
			voiceState={voiceState}
			predicate={(text) => textContainsWords(text, words, minWordCount)}
			stopOnSuccess={stopOnSuccess}
			onSuccess={onSpell}
		/>
	);
}
