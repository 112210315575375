import "./ChannelPage.css";
import ChannelPageChat from "./ChannelPageChat";
import VideoPlayer from "../common/VideoPlayer";
import MagicEffect2 from "../common/MagicEffect2";
import SpellListener from "../common/SpellListener";
import { CHANNELS_BY_ID } from "../../channelData";
import State from "../../state";
import { channelSpellActivatedEvent } from "../../analytics";

import GestureRecognizer from "@faintlines/gestures";

import { useParams } from "react-router-dom";
import { useEffect, useCallback } from "react";
import { observer } from "mobx-react-lite";

export default observer(function ChannelPage() {
	const { channelId } = useParams();
	const channel = CHANNELS_BY_ID[channelId];
	const { magic } = channel;

	useEffect(() => {
		State.setActiveChannel(channelId);
		return () => State.unsetActiveChannel();
	});

	const magicHandler = useCallback(
		(evt) => {
			State.activateMagic(magic.durationMs, magic.sideEffect);
			channelSpellActivatedEvent(
				channelId,
				evt && evt.name ? "gesture" : "voice"
			);
		},
		[magic, channelId]
	);

	return (
		<div className="ChannelPage">
			<ChannelPageMain channel={channel} />
			<ChannelPageChat channel={channel} />
			{magic && magic.gesture ? (
				<GestureRecognizer
					onGesture={magicHandler}
					gestures={[magic.gesture]}
				/>
			) : null}
			{magic && magic.words ? (
				<SpellListener
					words={magic.words}
					minWordCount={magic.minWordCount}
					onSpell={magicHandler}
					stopOnSuccess={false}
				/>
			) : null}
		</div>
	);
});

function ChannelPageMain({ channel }) {
	return (
		<div className="ChannelPageMain">
			<ChannelPageVideo channel={channel} />
			<div className="ChannelPageMain-info">
				<div
					className="ChannelPageMain-user-image"
					style={{
						backgroundImage: `url(${channel.user_image})`,
					}}
				/>
				<div className="ChannelPageMain-info-text">
					<div className="ChannelPageMain-user-name">
						{channel.user_name}
					</div>
					<div className="ChannelPageMain-title">{channel.title}</div>
				</div>
			</div>
		</div>
	);
}

const ChannelPageVideo = observer(function ({ channel }) {
	const { magic } = channel;

	return (
		<div className="ChannelPageVideo">
			<VideoPlayer
				source={channel.video}
				loop={channel.loop}
				startTime={State.channelTime[channel.id]}
				autoPlay
			/>
			{State.magicActive && magic && magic.effect ? (
				<MagicEffect2
					durationMs={5000}
					size={150}
					style={{
						position: "absolute",
						top: magic.effect.top,
						left: magic.effect.left,
					}}
				/>
			) : null}
		</div>
	);
});
