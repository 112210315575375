import "./HomePage.css";
import ChannelGrid from "../layout/ChannelGrid";
import { RECOMMENDED_CHANNELS, TRENDING_CHANNELS } from "../../channelData";

export default function HomePage() {
    return (
        <div className="HomePage">
            <ChannelGrid
                title="Live channels we think you'll like"
                channels={RECOMMENDED_CHANNELS}
            />
            <ChannelGrid
                title="Trending live channels"
                channels={TRENDING_CHANNELS}
            />
        </div>
    );
}
