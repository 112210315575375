import "./Logo.css";

export default function Logo({ size }) {
	size = size || 50;

	return (
		<img
			src={require("../../media/logo512.png").default}
			alt="Wizards Place"
			className="Logo"
			width={size}
			height={size}
			style={{
				height: size,
				width: size,
			}}
		/>
	);
}
