import { textContainsWords } from "./utils/textUtils";

const FETCH_OBJECT_SPELL = {
	words: ["holy", "bubble", "bring", "trouble"],
	minWordCount: 2,
	gesture: "sign1",
	durationMs: 5000,
};

const MAGIC_WORDS = [
	"holy",
	"bubble",
	"bring",
	"trouble",
	"mighty",
	"jolt",
	"release",
	"bolt",
	"spectral",
	"cloud",
	"hear",
	"loud",
	"bright",
	"flickers",
	"flicker",
	"swap",
	"liquors",
	"liquor",
];

function chatMessageHandler(text, postNewMessage) {
	if (text && textContainsWords(text.toLowerCase(), MAGIC_WORDS, 2)) {
		setTimeout(() => {
			postNewMessage({
				user: "ADMIN",
				text: "Posting spells in the Chat is strictly prohibited!",
			});
		}, 1000);
	}
}

export const ALL_CHANNELS = [
	{
		id: "whitepearl",
		title: "Flying With My Broom in NY",
		image: require("./media/channels/001/image.jpg").default,
		video: "https://storage.googleapis.com/wizards-place/flying.mp4",
		loop: true,
		user_name: "White Pearl",
		user_image: require("./media/channels/001/user.jpg").default,
		watching: 1721,
		live: true,
		onChatMessage: chatMessageHandler,
		chat: [
			{
				time: 1,
				user: "Zaqihr",
				text: "wow sooo fast",
			},
			{
				time: 5,
				user: "Katarum",
				text: "mirabile!",
			},
			{
				time: 10,
				user: "Onifeus",
				text: "Quid est hoc iuniperorum erat cibus?",
			},
		],
		magic: {
			...FETCH_OBJECT_SPELL,
			effect: {
				left: "50%",
				top: "80%",
			},
			sideEffect: {
				delayMs: 3000,
				type: "popupImage",
				imageUrl: require("./media/channels/001/broom.jpg").default,
			},
		},
	},
	{
		id: "ugudor",
		title: "Telekinesis 101 - Lesson 712 - Candle Flame",
		image: require("./media/channels/002/image.jpg").default,
		video: require("./media/channels/002/video.mp4").default,
		loop: true,
		user_name: "Ugudor",
		user_image: require("./media/channels/002/user.jpg").default,
		watching: 512,
		live: true,
		onChatMessage: chatMessageHandler,
		chat: [{ time: 10, user: "Aviar", text: "bumpin'" }],
	},
	{
		id: "zedor",
		title: "Potion Making Fundamentals",
		image: require("./media/channels/003/image.jpg").default,
		video: "https://advusercontent.com/wizards-potionmaker.mp4",
		loop: false,
		user_name: "Zedor",
		user_image: require("./media/channels/003/user.jpg").default,
		watching: 9128,
		live: true,
		onChatMessage: chatMessageHandler,
		chat: [
			{
				time: 5,
				user: "Unoviar",
				text: "Zedor can you show how to make a love potion?",
			},
			{
				time: 10,
				user: "Enuquam",
				text: "Unoviar you just missed it...",
			},
			{
				time: 13,
				user: "Unoviar",
				text: ":(",
			},
			{
				time: 20,
				user: "Ashaniar",
				text: "Zedor be careful! Your customers book is visible!! Any novice wizard can steal it from you if they master the right spell...",
			},
			{
				time: 25,
				user: "Ovatorn",
				text: "Do you sell the Perpetuo potion?",
			},
			{
				time: 30,
				user: "Enuquam",
				text: "Ovatorn not to anyone, it's too dangerous! You need to send him a text message.",
			},
			{
				time: 33,
				user: "Enuquam",
				text: "[+1 626 465 3155](sms:+16264653155)",
			},
			{
				time: 37,
				user: "Ovatorn",
				text: "Thanks!",
			},
			// Udaronin
		],
		magic: {
			...FETCH_OBJECT_SPELL,
			effect: {
				left: "54%",
				top: "71%",
			},
			sideEffect: {
				delayMs: 3000,
				type: "popupImage",
				imageUrl: require("./media/channels/003/store-transactions.jpg")
					.default,
			},
		},
	},
	{
		id: "onaxx",
		title: "A Journey to Another Dimension",
		image: require("./media/channels/004/image.jpg").default,
		video: "https://storage.googleapis.com/wizards-place/neon.mov",
		loop: true,
		user_name: "Onaxx",
		user_image: require("./media/channels/004/user.jpg").default,
		watching: 211,
		live: true,
		onChatMessage: chatMessageHandler,
		chat: [
			{ time: 4, user: "Ojamar", text: "Mad love from Neverland!" },
			{ time: 15, user: "Olzizin", text: "Beautiful!" },
			{ time: 25, user: "Aviar", text: "thank you for doing this" },
		],
	},
	{
		id: "trujahr",
		title: "Showing off my new weight increase spell",
		image: require("./media/channels/005/image.jpg").default,
		video: "https://storage.googleapis.com/wizards-place/dumbbel.mov",
		loop: true,
		user_name: "Trujahr",
		user_image: require("./media/channels/005/user.jpg").default,
		watching: 912,
		live: true,
		onChatMessage: chatMessageHandler,
		chat: [
			{ time: 10, user: "Uzokalis", text: "Love your vibe" },
			{ time: 20, user: "Aviar", text: "This is amazing!" },
		],
		magic: {
			...FETCH_OBJECT_SPELL,
			effect: {
				left: "50%",
				top: "70%",
			},
			sideEffect: {
				delayMs: 3000,
				type: "popupImage",
				imageUrl: require("./media/channels/005/dumbbells.jpg").default,
			},
		},
	},
	{
		id: "spellpractice",
		title: "Spell Workshop - Fetch an Object",
		image: require("./media/channels/006/image.jpg").default,
		video: "https://storage.googleapis.com/wizards-place/practice-fetch.mp4",
		loop: true,
		user_name: "Corbin's School of Magic",
		user_image: require("./media/channels/006/user.jpg").default,
		watching: 912,
		live: true,
		onChatMessage: chatMessageHandler,
		chat: [{ time: 20, user: "Aviar", text: "It works!!!" }],
		magic: {
			...FETCH_OBJECT_SPELL,
			effect: {
				left: "50%",
				top: "78%",
			},
			sideEffect: {
				delayMs: 3000,
				type: "popupImage",
				imageUrl: require("./media/channels/006/watermelon.jpg")
					.default,
			},
		},
	},
];

// 4. Abajur
// 5. Elocros
// 6. Meprot
// 7. Ozmazin
// 8. Zodomar
// 9. Crozimor
// 10. Hulmog
// 11. Tutamor
// 11. Zodarag
// 12. Ojara
// 13. Zardoz
// 14. Ahmahk
// 15. Zalazar
// 16. Orocros
// 17. Ogamar
// 18. Zuzendar
// 19. Imuvar
// 20. Zalazar
// 21. Imog
// 22. Abazor
// 23. Ojemar

export const CHANNELS_BY_ID = [];
ALL_CHANNELS.forEach((ch) => (CHANNELS_BY_ID[ch.id] = ch));

export const RECOMMENDED_CHANNELS = [
	CHANNELS_BY_ID.whitepearl,
	CHANNELS_BY_ID.ugudor,
	CHANNELS_BY_ID.zedor,
];

export const TRENDING_CHANNELS = [
	CHANNELS_BY_ID.trujahr,
	CHANNELS_BY_ID.spellpractice,
	CHANNELS_BY_ID.onaxx,
];
