import "./MagicEffect2.css";

import { useState, useEffect } from "react";
import { Howl } from "howler";
import classnames from "classnames";

export default function MagicEffect2({ size, style, durationMs }) {
	const [enter, setEnter] = useState(false);
	const [exit, setExit] = useState(false);
	const [done, setDone] = useState(false);

	useEffect(() => {
		new Howl({
			src: [require("../../media/spell2.mp3").default],
		}).play();

		const timeouts = [
			setTimeout(() => setEnter(true), 1),
			setTimeout(() => setDone(true), durationMs),
			setTimeout(() => setExit(true), durationMs - 600),
		];

		return () => timeouts.forEach(clearTimeout);
	}, [durationMs]);

	if (done) {
		return null;
	}

	return (
		<section
			className={classnames("MagicEffect2", { enter, exit })}
			style={{
				width: size,
				height: size,
				perspective: size * 4,
				marginTop: -0.5 * size,
				marginLeft: -0.5 * size,
				...style,
			}}
		>
			<figure className="MagicEffect2-bubble"></figure>
		</section>
	);
}
