import "./buttons.css";

import classnames from "classnames";

export function Button({ text, inline, large, onClick }) {
	return (
		<div
			className={classnames("Button", { inline, large })}
			onClick={onClick}
		>
			{text}
		</div>
	);
}
