import { event } from "@faintlines/monitoring";

export function hideWelcomeModalEvent() {
	event({
		category: "Progress",
		action: "Hide welcome modal",
	});
}

export function loginSpellActivatedEvent(spellType) {
	event({
		category: "Progress",
		action: "Login spell activated",
		label: spellType,
	});
}

export function channelSpellActivatedEvent(channelId, spellType) {
	event({
		category: "Progress",
		action: "Channel spell activated",
		label: spellType,
	});
}
