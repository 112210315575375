import "./VideoPlayer.css";

import { useState, useEffect, useRef } from "react";
import classnames from "classnames";

export default function VideoPlayer({
	source,
	poster,
	loop,
	startMuted,
	startTime,
	autoPlay,
}) {
	const videoRef = useRef();
	const [playing, setPlaying] = useState(autoPlay);
	const [muted, setMuted] = useState(startMuted);
	const [, setEnded] = useState(false);

	function handlePlayingChange() {
		if (videoRef.current) {
			setPlaying(!videoRef.current.paused);
		}
	}

	function handleEnded() {
		if (!loop) {
			setEnded(true);
		}
	}

	function handleMetadataLoaded() {
		if (typeof startTime === "number" && videoRef.current) {
			if (startTime < videoRef.current.duration) {
				videoRef.current.currentTime = startTime;
			} else if (loop) {
				videoRef.current.currentTime =
					startTime % videoRef.current.duration;
			} else {
				setEnded(true);
			}
		}
	}

	function toggleVideo(play) {
		if (videoRef.current) {
			if (play) {
				videoRef.current.play();
			} else {
				videoRef.current.pause();
			}
		}
	}

	useEffect(() => {
		if (autoPlay && videoRef.current && videoRef.current.paused) {
			try {
				videoRef.current.play().catch((err) => {});
			} catch (err) {
				//nothing
			}
		}
		handlePlayingChange();
	}, [autoPlay]);

	return (
		<div className="VideoPlayer">
			<video
				ref={videoRef}
				src={source}
				poster={poster}
				className="VideoPlayer-video"
				preload="auto"
				autoPlay={autoPlay}
				loop={loop}
				playsInline
				disableRemotePlayback
				disablePictureInPicture
				muted={muted}
				onPlay={handlePlayingChange}
				onPause={handlePlayingChange}
				onEnded={handleEnded}
				onLoadedMetadata={handleMetadataLoaded}
			/>
			<PlayButton
				color="#fff"
				visible={!playing}
				onClick={() => toggleVideo(true)}
			/>
			<Controls
				playing={playing}
				muted={muted}
				onPause={() => toggleVideo(false)}
				onPlay={() => toggleVideo(true)}
				onMute={() => setMuted(true)}
				onUnmute={() => setMuted(false)}
			/>
			<div className="VideoPlayer-live">{"LIVE"}</div>
		</div>
	);
}

function PlayButton({ color, visible, onClick }) {
	return (
		<div
			className={classnames("VideoPlayer-PlayButton", {
				visible,
			})}
			onClick={onClick}
		>
			<i className="icon-play-1" />
		</div>
	);
}

function Controls({ playing, muted, onPlay, onPause, onMute, onUnmute }) {
	return (
		<div
			className={classnames("VideoPlayer-Controls", {
				visible: !playing,
			})}
		>
			{playing ? (
				<div
					className="VideoPlayer-Controls-button pause"
					onClick={onPause}
				>
					<i className="icon-pause-1" />
				</div>
			) : (
				<div
					className="VideoPlayer-Controls-button play"
					onClick={onPlay}
				>
					<i className="icon-play-1" />
				</div>
			)}
			{muted ? (
				<div
					className="VideoPlayer-Controls-button unmute"
					onClick={onUnmute}
				>
					<i className="icon-volume-off" />
				</div>
			) : (
				<div
					className="VideoPlayer-Controls-button mute"
					onClick={onMute}
				>
					<i className="icon-volume-up-1" />
				</div>
			)}
		</div>
	);
}
