import "./LoginPage.css";
import SpellListener from "../common/SpellListener";
import Logo from "../common/Logo";
import State from "../../state";
import { loginSpellActivatedEvent } from "../../analytics";
import magicVideo from "../../media/magic1.mp4";

import GestureRecognizer from "@faintlines/gestures";

import { useEffect, useState, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import { observer } from "mobx-react-lite";
import { Howl } from "howler";

function LoginPage() {
	const [animationInProgress, setAnimationInProgress] = useState(false);
	const [loggedIn, setLoggedIn] = useState(false);
	const magicAudioRef = useRef(
		new Howl({
			src: [require("../../media/spell1.mp3").default],
		})
	);
	const videoRef = useRef();
	const history = useHistory();

	const initiateLogin = useCallback(
		(evt) => {
			setAnimationInProgress(true);
			videoRef.current.play();
			magicAudioRef.current.play();
			setTimeout(() => {
				setLoggedIn(true);
				State.logIn();

				setTimeout(() => history.push("/"), 3000);
			}, 3000);
			loginSpellActivatedEvent(evt && evt.name ? "gesture" : "voice");
		},
		[history]
	);

	useEffect(() => {
		const video = videoRef.current;
		video.controls = false;
		video.load();
		video.play().then(() => {
			video.pause();
			video.currentTime = 0;
		});
	}, []);

	return (
		<div className="LoginPage">
			<BackgroundVideo
				videoRef={videoRef}
				visible={animationInProgress || loggedIn}
			/>
			<LoginForm
				animated={animationInProgress && !loggedIn}
				hidden={loggedIn}
			/>
			<div
				className={classnames("LoginPage-welcome", {
					visible: loggedIn,
				})}
			>
				{"Welcome, Wizard!"}
			</div>
			<GestureRecognizer onGesture={initiateLogin} gestures={["sign5"]} />
			<SpellListener
				words={["mighty", "jolt", "release", "bolt"]}
				minWordCount={2}
				onSpell={initiateLogin}
			/>
		</div>
	);
}

export default observer(LoginPage);

function BackgroundVideo({ videoRef, visible }) {
	return (
		<div
			className={classnames("LoginPage-video-wrapper", {
				hidden: !visible,
			})}
		>
			<video
				src={magicVideo}
				className="LoginPage-success-video"
				preload="auto"
				muted
				playsInline
				disablePictureInPicture
				disableRemotePlayback
				ref={videoRef}
			/>
		</div>
	);
}

function LoginForm({ animated, hidden }) {
	const [failAnimation, setFailAnimation] = useState(false);

	function handleSubmit(evt) {
		evt.preventDefault();
		setFailAnimation(true);
		setTimeout(() => {
			setFailAnimation(false);
		}, 300);
	}

	return (
		<form
			className={classnames("LoginPage-form", {
				animated,
				hidden,
				failAnimation,
			})}
			onSubmit={handleSubmit}
			autoComplete="off"
		>
			<Logo size={100} />
			<input
				type="text"
				className="LoginPage-input data-hj-allow"
				placeholder="User name"
				data-lpignore="true"
			/>
			<input
				type="password"
				className="LoginPage-input lastpass-fake-search"
				placeholder="Password"
				data-lpignore="true"
			/>
			<button className="LoginPage-submit">{"Enter"}</button>
		</form>
	);
}
