import "./Modal.css";

import classnames from "classnames";
import { useState, useEffect } from "react";

export default function Modal({
	title,
	children,
	noPadding,
	enterAnimation,
	onClose,
}) {
	const [enter, setEnter] = useState(!enterAnimation);

	useEffect(() => {
		if (enterAnimation) {
			setTimeout(() => setEnter(true), 10);
		}
	}, [enterAnimation]);

	return (
		<div className={classnames("Modal", { enter })} onClick={onClose}>
			<div
				className={classnames("Modal-card", { enter, noPadding })}
				onClick={(evt) => evt.stopPropagation()}
			>
				<div className="Modal-contents">
					<div className="Modal-close-button" onClick={onClose}>
						{"x"}
					</div>
					{title ? <div className="Modal-title">{title}</div> : null}
					<div className="Modal-text">{children}</div>
				</div>
			</div>
		</div>
	);
}

export function ModalCenter({ children }) {
	return <div className="Modal-center">{children}</div>;
}
