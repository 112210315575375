import "./App.css";

import SiteHeader from "./components/layout/SiteHeader";
import HomePage from "./components/pages/HomePage";
import ChannelPage from "./components/pages/ChannelPage";
import LoginPage from "./components/pages/LoginPage";
import WelcomeModal from "./components/modals/WelcomeModal";
import ImageModal from "./components/modals/ImageModal";
import State from "./state";

import Widget from "@faintlines/widget";
import "@faintlines/widget/dist/index.css";

import { Router, Switch, Route, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";

export default observer(function App({ history }) {
    return (
        <Router history={history}>
            <div className="App">
                <Switch>
                    <Route path="/login" exact>
                        <LoginPage />
                    </Route>
                    <Route>
                        <SiteContent />
                    </Route>
                </Switch>
                <Widget
                    config={{
                        dialogTitle: "Hello, Wizard!",
                        position: "bottomLeft",
                        chat: {
                            method: "iframe",
                            iframeSrc:
                                "https://tawk.to/chat/6087a0775eb20e09cf36e5b8/1f48slkq1",
                        },
                    }}
                    storyConfig={{ id: "wizards", assetId: "streaming" }}
                />
            </div>
        </Router>
    );
});

const SiteContent = observer(() => {
    if (!State.loggedIn) {
        return <Redirect to="/login" />;
    }

    return (
        <>
            <SiteHeader />
            <Switch>
                <Route path="/watch/:channelId" exact>
                    <ChannelPage />
                </Route>
                <Route path="/" exact>
                    <HomePage />
                </Route>
            </Switch>
            <WelcomeModal />
            <ImageModal />
        </>
    );
});
