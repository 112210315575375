import "./ChannelGrid.css";
import { Link } from "react-router-dom";

export default function ChannelGrid({ title, channels }) {
	return (
		<div className="ChannelGrid">
			<div className="ChannelGrid-title">{title}</div>
			<div className="ChannelGrid-grid">
				{channels.map((ch) => (
					<ChannelGridItem key={ch.id} channel={ch} />
				))}
			</div>
		</div>
	);
}

function ChannelGridItem({ channel }) {
	return (
		<Link to={`/watch/${channel.id}`} className="ChannelGridItem">
			<div
				className="ChannelGridItem-image"
				style={{ backgroundImage: `url(${channel.image})` }}
			/>
			<div className="ChannelGridItem-info">
				<div
					className="ChannelGridItem-user-image"
					style={{
						backgroundImage: `url(${channel.user_image})`,
					}}
				/>
				<div className="ChannelGridItem-info-text">
					<div className="ChannelGridItem-title">{channel.title}</div>
					<div className="ChannelGridItem-user-name">
						{channel.user_name}
					</div>
				</div>
			</div>
			<div className="ChannelGridItem-live">{"LIVE"}</div>
		</Link>
	);
}
