import "./ImageModal.css";
import Modal from "../layout/Modal";
import State from "../../state";

import { observer } from "mobx-react-lite";

export default observer(function ImageModal() {
	if (!State.popupImageUrl) {
		return null;
	}

	function handleClose() {
		State.hidePopupImage();
	}

	return (
		<Modal noPadding enterAnimation onClose={handleClose}>
			<img
				className="ImageModal-image"
				src={State.popupImageUrl}
				alt=""
			/>
		</Modal>
	);
});
