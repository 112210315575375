import styles from "./SiteHeader.module.css";
import Logo from "../common/Logo";
import State from "../../state";

import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

function SiteHeader() {
	return (
		<header className={styles.header}>
			<Link to="/">
				<Logo size={40} />
			</Link>
			<Link to="/" className={styles.link}>
				{"Browse"}
			</Link>
			<div className={styles.right}>
				<div
					className={styles.logoutButton}
					onClick={() => State.logOut()}
				>
					{"Sign Out"}
				</div>
			</div>
		</header>
	);
}

export default observer(SiteHeader);
