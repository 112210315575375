import "./index.css";
import App from "./App";
import "./fonts/fontello/fontello-embedded.css";

import { initAnalytics, initSentry } from "@faintlines/monitoring";

import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";

initSentry({
	dsn: "https://c00454cf9c2e4d1b9b00fef8fab22829@o574655.ingest.sentry.io/5729407",
});

const history = createBrowserHistory();

initAnalytics({ trackingId: "UA-194968253-3", history });

ReactDOM.render(
	<React.StrictMode>
		<App history={history} />
	</React.StrictMode>,
	document.getElementById("root")
);
